import React from "react"
import { Link } from "gatsby"
import Ample from "../components/ample/ample"
import ExplainerPopover from "../components/general/explainerpopover"
import TradingViewMarketOverview from "../components/tradingview/marketoverview"
import TradingViewMostActive from "../components/tradingview/mostactive"

const IndexPage = (props) => {
  const pc_chart_url=`${process.env.GATSBY_CONTENT_SITE_URL}/plots/pc_ratio.html`;
  const seoDescription="Free simple tools for the active trader. Put/Call ratios, max pain, option pricer, spread finder tool.";

  return (
    <Ample  currPageTitle="Market Overview" seoDescription={seoDescription}  location={props.location.href}>
      <div className="row" >
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12" style={{minWidth:"380px",maxWidth:"380px"}} >
            <div className="card"  >
              <div className="card-body">
                      <h4 className="card-title" >Major Indexes</h4>
                      <TradingViewMarketOverview />
              </div>
            </div>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12" style={{minWidth:"380px",maxWidth:"380px"}}  >
        <div className="card" > 
              <div className="card-body">
                  <h4 className="card-title" >Today's most active</h4>
                  <TradingViewMostActive/>
              </div>  
        </div>   
      </div>    
      </div>

      <div className="row" >
        <div className="col-lg-12 col-sm-12 col-xs-12">
          <div className="card">
            <div className="card-body">
              <h4 className="card-title">
                Equity-only Put/Call Ratios  
                <ExplainerPopover>
                  Here we chart the ratio of open option interest in puts vs. calls (a measure of the amount of caution in the market).  It is sometimes the case that when this ratio's trendline 'rolls over' and starts a new trend, the overall stock market soon follows.
                </ExplainerPopover>
              </h4>
              <h6 className="card-subtitle">More detailed analysis and comparison to S&P 500 index available at <Link to="/pcratios/">PC Ratios page</Link></h6>
              <iframe 
                src={pc_chart_url}
              // border="0" width="640" height="400" 
                style= {{border:"0"  ,width: "100%",height: "400px" }}
                title="Put/Call Ratios">
                  
              </iframe>
            </div>
          </div>
        </div>
      </div>
  </Ample>
  );
};
export default IndexPage;