import React from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import TVParams from "./marketoverviewparams.json";

export default class TradingViewMarketOverview extends React.Component {

  //fetch TradingView's javascript file and inject it into the control
  // the tvparams json must be injected into the script
  injectTradingViewJavascript() {
    const script = document.createElement('script')
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js"
    script.async = true
    script.innerHTML = JSON.stringify(TVParams) //(this.tvparams)
    //inject this snippet into the container
    // this will cause tradingview-widget-container__widget to be populated with a rendering of the widget
    document.getElementById("tradingingviewmarketoverviewwidgetcontainer").appendChild(script)
  }
  
  //Once we are sure the component has rendered, inject a javascript controller from TradingView into the control
  componentDidMount(){
    this.injectTradingViewJavascript();
  }

  //TradingViewMarketOverview renders as a div which will be populated by the RefreshChart() method
  //  tradingingviewmarketoverviewwidgetcontainer is the main container. 
  //  tradingview-widget-container__widget is the div which contains the widget. we render an empty one to start
  //  tradingview-widget-copyright renders a copyright
  render() {
    return (
    <div id="tradingingviewmarketoverviewwidgetcontainer" className="tradingview-widget-container">
      <div className="tradingview-widget-container__widget"></div>
      <div className="tradingview-widget-copyright">
        <OutboundLink href="https://www.tradingview.com/markets/" rel="noreferrer noopener" target="_blank">
          <span className="blue-text">Financial Markets</span></OutboundLink> by TradingView
      </div>
      </div>
    )
  }

}
