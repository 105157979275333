import React from "react"
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import GenericPopover from "./genericpopover";

export default class ExplainerPopover extends GenericPopover
{
    explainerBlurb() {
        return <div className="font-light">
            {this.props.children}
            </div>
    } 
    /* GenericPopover overrides */
    triggerIcon() {
        return <HelpOutlineIcon color="primary"   />
    }
    contentBlurb() { return this.explainerBlurb();}
    defaultMaxWidth() { return "320px"; }
    defaultMinWidth() { return "320px" }
    
  }