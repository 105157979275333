import React from "react";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import TVParams from "./mostactiveparams.json";

export default class TradingViewMostActive extends React.Component {
  //fetch TradingView's javascript file and inject it into the control
  // the tvparams json must be injected into the script
  injectTradingViewJavascript() {
    const script = document.createElement('script')
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-hotlists.js"
    script.async = true
    script.innerHTML = JSON.stringify(TVParams) //(this.tvparams)
    //inject this snippet into the container
    // this will cause tradingview-widget-container__widget to be populated with a rendering of the widget
    document.getElementById("tradingviewmostactivewidgetcontainer").appendChild(script)
  }

  //Once we are sure the component has rendered, inject a javascript controller from TradingView into the control
  componentDidMount(){
    this.injectTradingViewJavascript();
  }

  //TradingViewMostActive renders as a div which will be populated by the RefreshChart() method
  //  tradingviewmostactivewidgetcontainer is the main container. 
  //  tradingview-widget-container__widget is the div which contains the widget. we render an empty one to start
  //  tradingview-widget-copyright renders a copyright
  render() {
    return (
      <div id="tradingviewmostactivewidgetcontainer" className="tradingview-widget-container" >
        <div  className="tradingview-widget-container__widget"></div>
        <div className="tradingview-widget-copyright">
        <OutboundLink href="https://www.tradingview.com/markets/stocks-usa/" rel="noreferrer noopener" target="_blank">
          <span className="blue-text">Stock Market Today</span></OutboundLink> by TradingView
        </div>
      </div>
    );
  }
}





