import React from "react"
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export default class GenericPopover extends React.Component 
{
    state = {
        popoverAnchorEl:null
    }
    //when the user clicks the button to request opening of the popover
    //  set the anchor element 
    // presence of the element in state controls whether the popover is open
    handlePopoverOpen=(event)=>{
        //unless we've been asked to anchor to some other element on the screen, we'll anchor to the icon that spawned us
        const anchorEl = (this.props.externalAnchorElement)?this.props.externalAnchorElement:event.currentTarget
        this.setState({popoverAnchorEl: anchorEl})
    }
    //when the user closes the popover (by clicking away for instance), be sure to clear
    // the reference to the anchor element
    handlePopoverClose=(event)=>{
        this.closePopover();
    } 
    closePopover=()=>{
        this.setState({popoverAnchorEl:null});
    }
    maxWidth() {
        return (this.props.maxWidth)?this.props.maxWidth: this.defaultMaxWidth();
    }
    minWidth() {
        let min = (this.props.minWidth)?this.props.minWidth: this.defaultMinWidth();
        //if we've ended up with a min that's more than the max...
        //give the max priority (so we don't eclipse other content)
        if (min > this.maxWidth()) { 
            //unless ONLY min was specified
            if (!(this.minWidthSpecified() && !this.maxWidthSpecified())) {
                min = this.maxWidth()
            }
             
        }
        return min;
    }
    minWidthSpecified() { return (this.props.minWidth)?true:false}
    maxWidthSpecified() { return (this.props.maxWidth)?true:false}


    /*overridable methods*/
    defaultMaxWidth() { return ""; }
    defaultMinWidth() { return ""; }
    triggerIcon() {
        return (this.props.triggerIcon)?this.props.triggerIcon:<HelpOutlineIcon color="secondary"/>
    }
    contentBlurb() {
        return this.props.children;
    }
    callToAction() {
        return (this.props.callToAction)?this.props.callToAction:<></>
    }


    render() {
        return (
            <>
                <div style={{textAlign:"center", maxWidth:"100px"}}>
                    <IconButton onClick={this.handlePopoverOpen} >
                        {this.triggerIcon()}
                    </IconButton><br/>
                    {this.callToAction()}
                </div>

                <Popover 
                    onClose={this.handlePopoverClose}  
                    anchorEl={this.state.popoverAnchorEl} 
                    open={Boolean(this.state.popoverAnchorEl)} 
                    disableRestoreFocus>
                        <table>
                            <tbody>
                                <tr>
                                    <td valign="top" style={{padding:"10px",maxWidth:this.maxWidth(),minWidth:this.minWidth()}}>
                                        {this.contentBlurb()}
                                    </td>
                                    <td style={{maxWidth:"40px"}} valign="top" align="center">
                                        <IconButton onClick={this.handlePopoverClose} disableRipple={true} aria-label="Close" >
                                            <CloseIcon color="primary" fontSize="small"/>
                                        </IconButton>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                </Popover>
            </>
        )
    }
  }